import {TopUpRotation, WebChargeStatus} from "@/Enums"
import {IssuerCard} from "./IssuerCard";
import {IssuerCardDto} from "./IssuerCardDto";
import {IssuerCardsDto} from "@/models/IssuerCardsDto";

export class IssuerCards {
    public Id: number = 0;
    
    private cardsDto:IssuerCardsDto = new IssuerCardsDto();
    private cards: Array<IssuerCard> = new Array<IssuerCard>();

    public HasRequestErrors: boolean = false;
    public RequestErrors: object = {};
    public RequestError:string = "";

    private filterCardState: number = 0;
    private filterCardNumber: string = '';
    private filterCardReference: string = '';
    private filterCardRotation: TopUpRotation = TopUpRotation.All;
    private filterChargeState: WebChargeStatus = WebChargeStatus.All;
    private filterRotationStart: string = '';
    private filterRotationEnd: string = '';

    public get Cards() {
        let cards = this.cards;

        if (this.filterCardState === 1) {
            cards = cards.filter(x => x.IsActive);
        }
        if (this.filterCardState === 2) {
            cards = cards.filter(x => !x.IsActive);
        }
        if (this.filterCardNumber.trim().length > 0) {
            cards = cards.filter(x => x.CardNumber.toString().includes(this.filterCardNumber));
        }
        if (this.filterCardRotation !== TopUpRotation.All) {
            cards = cards.filter(x => x.Rotation === this.filterCardRotation);
        }
        if(this.filterRotationStart.trim().length > 0){
            cards = cards.filter(x => x.RotationStart === this.filterRotationStart);
        }
        if(this.filterRotationEnd.trim().length > 0){
            cards = cards.filter(x => x.RotationEnd === this.filterRotationEnd);
        }
        if (this.filterChargeState !== WebChargeStatus.All) {
            cards = cards.filter(x => x.ChargeStatus === this.filterChargeState);
        }
        if(this.filterCardReference.trim().length > 0) {
            cards = cards.filter(x => x.Reference?.includes(this.filterCardReference));
        }
        
        return cards;
    }

    public SetCards(cards: Array<IssuerCardDto>){
        this.cardsDto.cards = cards;
        this.SetIssuerCardDto();
    }

    public GetCards() : Array<IssuerCard>{
        return this.cards;
    }
    
    public GetModel() : IssuerCardsDto {
        let changedCards = this.cards.filter(x => x.HasChanges).map(x => x.GetModel())
        let changedCardsDto = new IssuerCardsDto();
        changedCardsDto.cards = changedCards;
        return changedCardsDto;
    }

    public GetCardNumbers() :Array<number>{
        return this.cards.map(x => x.CardNumber);
    }

    public GetErrorsAsStringArray(){
        let errors = new Array<string>()
        for (const [_, value] of Object.entries(this.RequestErrors)) {
            errors.push(value.toString());
        }
        if(this.RequestError) errors.push(this.RequestError);
        
        return errors
    }

    get HasChanges() {
        return this.cards.length !== this.cardsDto.cards.length
            || this.cards.filter(x => x.HasChanges).length > 0;
    }
   
    public FilterCards(
        cardState: number, 
        cardReference: string,
        cardNumber: string, 
        rotation: TopUpRotation, 
        rotationStart:string, 
        rotationEnd:string,
        chargeState: WebChargeStatus
    ) 
    {
        this.filterCardState = cardState;
        this.filterCardReference = cardReference;
        this.filterCardNumber = cardNumber;
        this.filterCardRotation = rotation;
        this.filterRotationStart = rotationStart;
        this.filterRotationEnd = rotationEnd;
        this.filterChargeState = chargeState;
    }

    public Reset(){        
        this.SetIssuerCardDto();
        this.ResetErrors();
    }

    public SetIssuerCardDto(){
        this.cards = new Array<IssuerCard>();
        this.cardsDto.cards.sort((x, y) => x.cardNumber - y.cardNumber).forEach(x => {
            this.cards.push(new IssuerCard(x));
        });
    }

    public ResetErrors(){
        this.HasRequestErrors = false;
        this.RequestErrors = {};
        this.RequestError = "";
    }

}

