import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
Vue.config.productionTip = false;

fetch(process.env.BASE_URL + "appsettings.json")
  .then((json) => {
    json.json().then((config) => {
       Vue.prototype.$config = config
       new Vue({
        vuetify,
        i18n,
        render: h => h(App),
      }).$mount('#app')
    })
})