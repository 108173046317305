export class ReportDownloadModel{
    public Data:any = undefined;
    public Filename:string = '';
    public StartDate:string = '';
    public EndDate?:string = '';

    constructor(filename: string, startDate: string, endDate?: string) {
        this.Filename = filename;
        this.StartDate = startDate;
        this.EndDate = endDate;
    }
}
