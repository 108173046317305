export enum View {
    Start,
    Login,
}

export enum TopUpRotation {
    None,
    Once,
    Monthly,
    Quarterly,
    Yearly,
    All
  }
  
  export enum WebChargeStatus {
    All = -2,
    Unknown = -1,
    Success,
    SystemError,
    ConfigurationError,
    MaxCardLimitError,
    MaxChargeLimitError
  }
  
  export  enum CredentialServiceError{
    Stateless = -2,
    Unknown = -1,
    None ,
    WrongCurrentPassword  ,
  }
  