

import Vue from "vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default Vue.extend({
  components: {BaseButton},
  props: {
    Headline:String,
    Content:String,
    ButtonConfirm:String,
    ButtonCancel:String,
    textColor: String,
    backgroundColor: String,
  },
  data: function () {
    return {
      isActive: false,
    };
  },
  methods: {
    Show: function(){
      this.isActive = true;
    },
    Hide: function (){
      this.isActive = false;
    },
    Cancel: function (){
      this.Hide();
      this.$emit("Canceled");
    },
    Confirm: function (){
      this.Hide();
      this.$emit("Confirmed");
    },
  },
});
