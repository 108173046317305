import { render, staticRenderFns } from "./AdjustPaycardSettingsDialog.vue?vue&type=template&id=a4b4b7fa"
import script from "./AdjustPaycardSettingsDialog.vue?vue&type=script&lang=ts"
export * from "./AdjustPaycardSettingsDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/assets/locales/i18n-adjust-paycard-settings-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2FAdjustPaycardSettingsDialog.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/assets/locales/i18n-paycards-overview.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2FAdjustPaycardSettingsDialog.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports