
import Vue from "vue";
import moment from "moment";
import AdjustPaycardSettingsDialog from "./AdjustPaycardSettingsDialog.vue";
import ExportCardReportDialog from "./ExportPaycardReportDialog.vue";
import {IssuerCard} from "@/models/IssuerCard";
import {TopUpRotation, WebChargeStatus} from "@/Enums";
import {ExportPaycards} from "@/models/ExportPaycards";
import {TokenService} from "@/TokenService";
import {CardLimite} from "@/models/CardLimite";
import {downloadReport} from "@/scripts/ProfiControlRequests/ReportRequests";
import ConfirmationDialog from "@/components/base/ConfirmationDialog.vue";
import FloatingActionButton from "@/components/buttons/FloatingActionButton.vue";
import {CatchCardError, IssuerCardsRequests, UpdateCards} from "@/scripts/ProfiControlRequests/IssuerCardsRequests";
import {IssuerCardImportDto} from "@/models/IssuerCardImportDto";
import {ImportRequests} from "@/scripts/ProfiControlRequests/ImportRequests";
import {ExportRequests} from "@/scripts/ProfiControlRequests/ExportRequests";
import CardImportResultDialog from "@/components/CardImportResultDialog.vue";
import UploadDialog from "@/components/base/UploadDialog.vue";
import InformationDialog from "@/components/base/InformationDialog.vue";
import {IssuerCards} from "@/models/IssuerCards";
import RequestErrorDialog from "@/components/base/RequestErrorDialog.vue";
import {SettingRequester} from "@/scripts/ProfiControlRequests/SettingRequester";
import {WebchargeSetting} from "@/models/WebchargeSetting";
import {debounce, getCurrentDate} from "@/scripts/Common/HelperFunctions";
import {DateFormats} from "@/scripts/Common/DateFormats";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TimeSpanDialog from "@/components/base/TimeSpanDialog.vue";
import {ReportDownloadModel} from "@/models/ReportDownloadModel";

export default Vue.extend({
  components: {
    TimeSpanDialog,
    BaseButton,
    RequestErrorDialog,
    InformationDialog,
    UploadDialog,
    CardImportResultDialog,
    FloatingActionButton,
    ConfirmationDialog,
    AdjustPaycardSettingsDialog,
    ExportCardReportDialog,
  },
  data: () => ({
    TopUpRotation,
    WebChargeStatus,
    filterCardState: 0,
    filterCardReference: "",
    filterCardNumber: "",
    filterRotationStart: "",
    filterRotationEnd: "",
    filterCardRotation: TopUpRotation.All,
    filterChargeState: WebChargeStatus.All,
    cardConfigurationValid: true,
    adjustPaycards: new IssuerCard(),
    exportPaycards: new ExportPaycards(),
    hasChanges: false,
    Model: new IssuerCards(),
    benched: 0,
    cardLimite: new CardLimite(),
    cardImportResult: new IssuerCardImportDto(),
    informationHeadline: "",
    informationContent: "",
    cardRequests: {
      errors: new Array<string>(),
      error: "",
    },
    confirmationDialog: {
      headline: "",
      content: "",
      confirm: "",
      cancel: "",
      function: ""
    },
    restrictedRotations: [TopUpRotation.Monthly, TopUpRotation.Quarterly, TopUpRotation.Yearly],
    issuerWebchargeSetting: new WebchargeSetting(),
    dataTable: {
      selected: new Array<IssuerCard>(),
      expanded: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10
    }
  }),
  created: function () {
    this.GetCardLimite();
    this.Model.Id = TokenService.getIssuerIdJwt(this.$cookies.get("token"));
    this.GetIssuerCards();
    this.GetWebchargeSetting();
  },
  computed: {
    rules() {
      return {
        requiredCondition: (condition: boolean, value: any) => {
          return (!condition || !!value) ? true : this.$t("Rules.Required");
        },
        validateReference: (value: IssuerCard) => {
          if (value.HasValidReference()) return true;
          return this.$t("Rules.Max", {maxLength: value.ReferenceMaxLength})
        },
        validateStartDate: (value: IssuerCard) => {
          if (value.HasValidRoationStart()) return true;
          return this.$t("Rules.MinDate", {minDate: this.$d(moment().add(1, 'days').toDate(), 'short')});
        },
        validateEndDate: (value: IssuerCard) => {
          if (value.HasValidRoationEnd()) return true;
          return this.$t("Rules.MinDate", {
            minDate: (!value.RotationStart || new Date().getTime() > new Date(value.RotationStart).getTime())
                ? this.$d(moment().add(1, 'days').toDate(), 'short')
                : this.$d(moment(value.RotationStart).add(1, 'days').toDate(), 'short')
          })
        },
        validateAmount: (value: IssuerCard) => {
          if (value.HasValidAmount(this.cardLimite)) return true;
          return this.restrictedRotations.includes(value.Rotation)
              ? this.$t("Rules.AmountBetween", {
                minValue: 1,
                maxValue: this.cardLimite.maxMonthlyTopUpAmount,
                decimals: value.MaximumDecimals
              })
              : this.$t("Rules.AmountBetween",
                  {
                    minValue: 1,
                    maxValue: this.cardLimite.maxCardLimit > this.cardLimite.topUpLimitPerMonth
                        ? this.cardLimite.topUpLimitPerMonth
                        : this.cardLimite.maxCardLimit,
                    decimals: value.MaximumDecimals
                  })
        },
      }
    },
    dataTableHeaders(): Array<any> {
      return [
        {text: this.$t('Paycard.CardNumber'), value: 'CardNumber'},
        {text: this.$t('Paycard.Reference'), value: 'Reference'},
        {text: this.$t('Paycard.Amount'), value: 'Amount'},
        {text: this.$t('Paycard.Rotation'), value: 'Rotation'},
        {text: this.$t('Paycard.RotationStart'), value: 'RotationStart'},
        {text: this.$t('Paycard.RotationEnd'), value: 'RotationEnd'},
        {text: this.$t('Paycard.ChargeState'), value: 'ChargeStatus'},
        {text: "", value: 'HasError'}
      ]
    },
    buttons(): any {
      return {
        externalActions: [
          {
            prependIcon: "mdi-application-import",
            text: this.$t('Button.ImportCardConfiguration'),
            css: "dropdownButtons",
            action: this.ShowUploadDialog
          },
          {
            prependIcon: "mdi-application-export",
            text: this.$t('Button.CardConfigurationExport'),
            css: "dropdownButtons",
            action: this.ShowExportDialog
          }
        ],
        reports: [
          {
            prependIcon: "mdi-file-delimited",
            text: this.$t('Button.CardReport'),
            css: "dropdownButtons",
            action: this.ShowCardReport
          },
          {
            prependIcon: "mdi-file-delimited",
            text: this.$t('Button.BudgetReport'),
            css: "dropdownButtons",
            action: this.ShowBudgetReportDialog
          },
          {
            prependIcon: "mdi-file-delimited",
            text: this.$t('Button.ChargeReport'),
            css: "dropdownButtons",
            action: this.showChargeReportDialog
          },
          {
            prependIcon: "mdi-file-delimited",
            text: this.$t('Button.ChargeLimitReport'),
            css: "dropdownButtons",
            action: this.showChargeLimitReportDialog
          }
        ],
      }
    },
    rotations(): Array<any> {
      return [
        {id: TopUpRotation.None, value: this.$t("Rotation.None")},
        {id: TopUpRotation.Once, value: this.$t("Rotation.Once")},
        {id: TopUpRotation.Monthly, value: this.$t("Rotation.Monthly")},
        {id: TopUpRotation.Quarterly, value: this.$t("Rotation.Quarterly")},
        {id: TopUpRotation.Yearly, value: this.$t("Rotation.Yearly")},
      ];
    },
    filterRotations(): Array<any> {
      return [
        {id: TopUpRotation.All, value: this.$t("Filter.None")},
        {id: TopUpRotation.None, value: this.$t("Rotation.None")},
        {id: TopUpRotation.Once, value: this.$t("Rotation.Once")},
        {id: TopUpRotation.Monthly, value: this.$t("Rotation.Monthly")},
        {id: TopUpRotation.Quarterly, value: this.$t("Rotation.Quarterly")},
        {id: TopUpRotation.Yearly, value: this.$t("Rotation.Yearly")},
      ];
    },
    cardStates(): Array<any> {
      return [
        {state: 0, value: this.$t("Filter.None")},
        {state: 1, value: this.$t("Filter.Active")},
        {state: 2, value: this.$t("Filter.Inactive")},
      ];
    },
    filterChargeStates(): Array<any> {
      return [
        {state: WebChargeStatus.All, value: this.$t("Filter.None")},
        {state: WebChargeStatus.Unknown, value: this.$t("WebChargeStatus.Unknown")},
        {state: WebChargeStatus.Success, value: this.$t("WebChargeStatus.Success")},
        {state: WebChargeStatus.SystemError, value: this.$t("WebChargeStatus.SystemError")},
        {state: WebChargeStatus.ConfigurationError, value: this.$t("WebChargeStatus.ConfigurationError")},
        {state: WebChargeStatus.MaxCardLimitError, value: this.$t("WebChargeStatus.MaxCardLimitError")},
        {state: WebChargeStatus.MaxChargeLimitError, value: this.$t("WebChargeStatus.MaxChargeLimitError")},
      ];
    },
    canSave(): boolean {
      return this.Model.HasChanges && this.cardConfigurationValid;
    },
    canShowAdjustPaycardsDialog(): boolean {
      return this.dataTable.selected.length > 0;
    },
    language(): string {
      return this.$i18n.locale.toString();
    },
    token(): string {
      return this.$cookies.get("token");
    },
    getMinDateRestriction(): string {
      return getCurrentDate(DateFormats.ISO8601_LONG);
    }
  },
  methods: {
    SelectAll(event: any) {
      event.value ? this.dataTable.selected = this.Model.Cards : this.dataTable.selected = [];
    },
    GetIssuerCards() {
      const url = `${this.$config.GatewayUrl}/api/v1/issuer-cards`
      IssuerCardsRequests.Get(url, this.token, this.Model);
    },
    GetWebchargeSetting() {
      const url = `${this.$config.GatewayUrl}/api/v1/webcharge/configuration/issuer`
      SettingRequester.getWebchargeSettings(url, this.token, this.issuerWebchargeSetting, this.language);
    },
    RefreshCardState() {
      const cardNumbers = this.Model.GetCardNumbers();
      const url = `${this.$config.GatewayUrl}/api/v1/issuer-cards/paycard-status/`
      IssuerCardsRequests.RefreshCardState(url, this.token, this.Model, cardNumbers);
    },
    RepeatWebcharge(card: IssuerCard) {
      const url = `${this.$config.GatewayUrl}/api/v1/issuer-cards/repeat-webcharge/${card.LastChargeProtocolId}`;
      IssuerCardsRequests.RepeateWebCharge(url, this.token, card);
    },
    DownloadBudgetReport() {
      const url = `${this.$config.GatewayUrl}/api/v1/issuer-reports/budget-report`;
      const reportName = this.$t("Filenames.BudgetReport").toString();
      const fileModel = new ReportDownloadModel(reportName, moment().format(DateFormats.YEAR_MONTH_DAY));
      downloadReport(url, this.token, fileModel, this.language);
    },
    downloadChargeReport(startDate: string, endDate: string) {
      const url = `${this.$config.GatewayUrl}/api/v1/issuer-reports/webcharge-report?startDate=${startDate}&endDate=${endDate}`
      const reportName = this.$t("Filenames.ChargeReport").toString();
      const fileModel = new ReportDownloadModel(reportName, startDate, endDate);
      downloadReport(url, this.token, fileModel, this.language);
    },
    downloadChargeLimitReport(startDate: string, endDate: string) {
      const url = `${this.$config.GatewayUrl}/api/v1/issuer-reports/chargelimit-report?startDate=${startDate}&endDate=${endDate}`
      const reportName = this.$t("Filenames.ChargeLimitReport").toString();
      const fileModel = new ReportDownloadModel(reportName, startDate, endDate);
      downloadReport(url, this.token, fileModel, this.language);
    },
    ImportCardConfiguration(file: any) {
      const url = `${this.$config.GatewayUrl}/api/v1/import/cards`;
      ImportRequests.CardConfigurationImport(url, this.token, file, this.cardImportResult, this.language);
    },
    ExportCardConfiguration() {
      const url = `${this.$config.GatewayUrl}/api/v1/card/configuration/export`;
      const exportBaseName = this.$t("Filenames.CardConfiguration").toString();
      ExportRequests.CardConfiguration(url, this.token, exportBaseName, this.language);
    },
    GetCardLimite() {
      const url = `${this.$config.GatewayUrl}/api/v1/card/limite`;
      IssuerCardsRequests.GetCardLimite(url, this.token, this.cardLimite);
    },
    async Save() {
      const url = `${this.$config.GatewayUrl}/api/v1/issuer-cards`
      const responseObject = await UpdateCards(url, this.token, this.Model, this.language)
      if (responseObject.responseData) {
        this.Model.SetCards(responseObject.responseData.data.cards)
        this.ResetSelection();
      }
      if (!responseObject.errorResponse) return;
      CatchCardError(responseObject.errorResponse, this.Model);
    },
    Reset() {
      this.Model.Reset();
      this.ResetSelection();
      this.RefreshCardState();
    },
    ResetSelection() {
      this.dataTable.selected = []
    },
    ShowAdjustPaycardsDialog() {
      (this.$refs.adjustPaycards as any).Show();
      this.adjustPaycards.Reset()
    },
    ConfirmAdjustPaycardsDialog() {
      for (let card of this.dataTable.selected) {
        card.Amount = this.adjustPaycards.Amount;
        card.Rotation = this.adjustPaycards.Rotation;
        card.RotationStart = this.adjustPaycards.RotationStart;
        card.RotationEnd = this.adjustPaycards.RotationEnd;
      }
    },
    ShowCardReport() {
      this.exportPaycards.ShowDialog = true;
      this.exportPaycards.IssuerId = this.Model.Id as number;
    },
    GetStatus(status: WebChargeStatus) {
      switch (status) {
        case WebChargeStatus.Success:
          return this.$t("WebChargeStatus.Success");
        case WebChargeStatus.SystemError:
          return this.$t("WebChargeStatus.SystemError");
        case WebChargeStatus.ConfigurationError:
          return this.$t("WebChargeStatus.ConfigurationError");
        case WebChargeStatus.MaxCardLimitError:
          return this.$t("WebChargeStatus.MaxCardLimitError");
        case WebChargeStatus.MaxChargeLimitError:
          return this.$t("WebChargeStatus.MaxChargeLimitError");
      }
    },
    ShowBudgetReportDialog() {
      this.FillConfirmationDialog(
          this.$t('ConfirmationDialog.BudgetReport.Headline').toString(),
          this.$t('ConfirmationDialog.BudgetReport.Content').toString(),
          this.$t('ConfirmationDialog.Buttons.Confirm').toString(),
          this.$t('ConfirmationDialog.Buttons.Cancel').toString(),
          this.DownloadBudgetReport);
      this.ShowConfirmationDialog();
    },
    ShowExportDialog() {
      this.FillConfirmationDialog(
          this.$t('ConfirmationDialog.CardConfigurationExport.Headline').toString(),
          this.$t('ConfirmationDialog.CardConfigurationExport.Content').toString(),
          this.$t('ConfirmationDialog.Buttons.Confirm').toString(),
          this.$t('ConfirmationDialog.Buttons.Cancel').toString(),
          this.ExportCardConfiguration);
      this.ShowConfirmationDialog();
    },
    ShowConfirmationDialog() {
      (this.$refs.confirmationDialog as any).Show();
    },
    FillConfirmationDialog(headline: string, content: string, confirm: string, cancel: string, callback: any) {
      this.confirmationDialog.headline = headline;
      this.confirmationDialog.content = content;
      this.confirmationDialog.confirm = confirm;
      this.confirmationDialog.cancel = cancel;
      this.confirmationDialog.function = callback;
    },
    ShowUploadDialog() {
      (this.$refs.uploadDialog as any).Show()
    },
    showChargeReportDialog() {
      (this.$refs.chargeReportDialog as any).show()
    },
    showChargeLimitReportDialog() {
      (this.$refs.chargeLimitReportDialog as any).show()
    },
    ResetIssuerCardResult() {
      this.cardImportResult = new IssuerCardImportDto();
      this.GetIssuerCards();
    },
    validateCardForm() {
      //No definition of parameters in documentation
      (this.$refs.CardConfiguration as any).validate();
    },
  },
  watch: {
    filterCardState: function (value) {
      this.Model.FilterCards(
          value,
          this.filterCardReference,
          this.filterCardNumber,
          this.filterCardRotation,
          this.filterRotationStart,
          this.filterRotationEnd,
          this.filterChargeState
      );
    },
    filterCardReference: function (value) {
      const filterFunction = () => {
        this.Model.FilterCards(
            this.filterCardState,
            value,
            this.filterCardNumber,
            this.filterCardRotation,
            this.filterRotationStart,
            this.filterRotationEnd,
            this.filterChargeState)
      }
      const execute = debounce(filterFunction);
      execute();
    },
    filterCardNumber: function (value) {
      const filterFunction = () => {
        this.Model.FilterCards(
            this.filterCardState,
            this.filterCardReference,
            value,
            this.filterCardRotation,
            this.filterRotationStart,
            this.filterRotationEnd,
            this.filterChargeState)
      };
      const execute = debounce(filterFunction);
      execute();
    },
    filterCardRotation: function (value) {
      this.Model.FilterCards(
          this.filterCardState,
          this.filterCardReference,
          this.filterCardNumber,
          value,
          this.filterRotationStart,
          this.filterRotationEnd,
          this.filterChargeState
      );
    },
    filterRotationStart: function (value) {
      this.Model.FilterCards(
          this.filterCardState,
          this.filterCardReference,
          this.filterCardNumber,
          this.filterCardRotation,
          value,
          this.filterRotationEnd,
          this.filterChargeState
      );
    },
    filterRotationEnd: function (value) {
      this.Model.FilterCards(
          this.filterCardState,
          this.filterCardReference,
          this.filterCardNumber,
          this.filterCardRotation,
          this.filterRotationStart,
          value,
          this.filterChargeState
      );
    },
    filterChargeState: function (value) {
      this.Model.FilterCards(
          this.filterCardState,
          this.filterCardReference,
          this.filterCardNumber,
          this.filterCardRotation,
          this.filterRotationStart,
          this.filterRotationEnd,
          value
      );
    },
    'Model.Cards': function () {
      this.RefreshCardState();
    },
    'cardImportResult.hasResult': function () {
      if (this.cardImportResult.hasResult) {
        (this.$refs.cardImportResultDialog as any).Show();
      }
    },
    'cardImportResult.hasCsvValidationError': function () {
      if (!this.cardImportResult.hasCsvValidationError) {
        return;
      }
      this.informationHeadline = this.$t("ErrorHeadline").toString();
      this.informationContent = this.cardImportResult.csvValidationError;
      (this.$refs.informationDialog as any).Show();
      this.cardImportResult.hasCsvValidationError = false;
      this.cardImportResult.csvValidationError = "";
    },
    'Model.HasRequestErrors': function () {
      if (!this.Model.HasRequestErrors) return;
      this.cardRequests.errors = new Array<string>();
      this.cardRequests.errors = this.Model.GetErrorsAsStringArray();
      (this.$refs.errorDialog as any).Show();
      this.Model.ResetErrors();
    }

  },
});
