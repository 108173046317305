import { render, staticRenderFns } from "./TimeSpanDialog.vue?vue&type=template&id=6032881a&scoped=true"
import script from "./TimeSpanDialog.vue?vue&type=script&lang=ts"
export * from "./TimeSpanDialog.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/scoped/dialog-style.css?vue&type=style&index=0&id=6032881a&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6032881a",
  null
  
)

/* custom blocks */
import block0 from "@/assets/locales/i18n-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2Fbase%2FTimeSpanDialog.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports