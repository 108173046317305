import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.startViewActive),expression:"startViewActive"}]},[_c(VRow,{staticClass:"mt-10 mb-2"},[_c(VCol,{attrs:{"align":"center","md":"12","sm":"12","xs":"12"}},[_c('h2',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t('Headline'))+" ")]),_c('BaseButton',{attrs:{"prependIcon":"mdi-account","cssClass":"ma-1 btn-text-start setting-buttons","buttonText":_vm.$t('Account-Button')},on:{"click":_vm.toggleAccountView}}),_c('BaseButton',{attrs:{"prependIcon":"mdi-logout","cssClass":"ma-1 btn-text-start setting-buttons","buttonText":_vm.$t('Logout-Button')},on:{"click":_vm.logout}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"align":"center","xs":"12","sm":"12","md":"12","lg":"12"}},[_c(VDivider,{staticClass:"divider-width"})],1)],1),_c(VRow,{staticClass:"mt-4 mb-4",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"auto","sm":"auto"}},[_c(VImg,{attrs:{"alt":"Operator-Logo","contain":"","src":require("@/assets/images/logo-left.png"),"width":"20vw","height":"20vh","transition":"scale-transition"}})],1),(_vm.hasPersonalizedIssuerLogo)?_c(VCol,{attrs:{"md":"auto","sm":"auto"}},[_c(VImg,{attrs:{"alt":"Issuer-Logo","width":"20vw","height":"20vh","contain":"","src":_vm.personalizedLogoModel.PersonalizedLogo.Data}})],1):_vm._e()],1),_c(VRow,[_c(VCol,{attrs:{"align":"center","xs":"12","sm":"12","md":"12","lg":"12"}},[_c(VDivider,{staticClass:"divider-width"})],1)],1),_c(VRow,{staticClass:"mt-5"},[_c(VCol,[_c(VCardTitle,{staticClass:"justify-center",attrs:{"primary-title":""}},[_c('div',[_c('PaycardsOverview')],1)])],1)],1)],1),_c('Account',{directives:[{name:"show",rawName:"v-show",value:(_vm.accountViewActive),expression:"accountViewActive"}],attrs:{"personalizedLogoModel":_vm.personalizedLogoModel},on:{"back":_vm.toggleAccountView,"logout":_vm.logout}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }