export class ImageDownload {

    public static Create(content:any, filename:string) {
        let fileLink = document.createElement("a");
        fileLink.setAttribute("href", content);
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
    }

}