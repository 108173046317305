
import Vue from 'vue';
import TextButton from "@/components/buttons/TextButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import {CredentialModel} from "@/models/CredentialModel";
import {AuthenticationRequests} from "@/scripts/ProfiControlRequests/AuthenticationRequests";
import {CredentialServiceError} from "@/Enums";

export default Vue.extend({
  components: {BaseButton, TextButton},
  data:() => ({
        credentialModel: new CredentialModel(),
        cardNumberEntered: false,
        cardExist: false,
        cardPasswordSet: true,
        showPassword: false,
        showPasswordRepeat: false,
        showError: false,
        format: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
        passwordRules: {
          MinLength: 4,
          MaxLength: 20,
          MinCountEnoughNumbers: 1,
          MinCountUpperChars: 1,
          MinCountLowerChars: 2,
          MinCountSpecialSigns: 2,
          Valid: false
        },
        cookieSettings: {
          livetime: "24h",
          secure: true,
          sameSite: "Strict"
        }
    }),
    computed: {
      continueValidation() : boolean
      {
        return !this.cardPasswordSet && !this.passwordRules.Valid;
      },
      onlyCardNumberEntered() : boolean
      {
        return (this.credentialModel.CurrentPassword == '' && this.credentialModel.NewPassword == '')
                && this.credentialModel.CardNumber != '';
      },
      startLogin() : boolean
      {
        return this.credentialModel.CurrentPassword != '' 
                && this.credentialModel.CardNumber != '' 
                && this.credentialModel.PasswordIsSet;
      },
      startRegister() : boolean
      {
        return this.credentialModel.NewPassword != '' 
                && this.credentialModel.NewPasswordConfirm != '' 
                && this.credentialModel.CardNumber != '' 
                && !this.credentialModel.PasswordIsSet
      },
      rules() {
        return {
            required: (value: string) => 
              !!value || 
              this.$t('PasswordRules.HasPassword'),
            sameValue:(valueA: string, valueB: string) => 
              valueA === valueB || 
              this.$t('PasswordRules.PasswordConfirmationInvalid'),
            max: (maxLength: number, value: string) =>
              value == undefined ||
              value.length <= maxLength ||
              this.$t('PasswordRules.HasPasswordExceededMaxLength', { maxLength: maxLength }),
            min: (minLength: number, value: string) =>
              value == undefined ||
              value.length >= minLength ||
              this.$t('PasswordRules.IsPasswordLongEnough', { minLength: minLength }),
            enoughNumbers: (minNumberCount: number, value: string) =>
              value == undefined ||
              (value.match(/\d/g) || []).length >= minNumberCount ||
              this.$t('PasswordRules.HasEnoughNumbers', { minNumberCount: minNumberCount }),
            enoughUpperCases: (minUpperCases: number, value: string) =>
              value == undefined ||
              (value.match(/[A-Z]/g) || []).length >= minUpperCases ||
              this.$t('PasswordRules.HasEnoughUpperChars', { minUpperCases: minUpperCases }),
            enoughLowerCases: (minLowerCases: number, value: string) =>
              value == undefined ||
              (value.match(/[a-z]/g) || []).length >= minLowerCases ||
              this.$t('PasswordRules.HasEnoughLowerChars', { minLowerCases: minLowerCases }),
            enoughSpecialSigns: (minSpecialSigns: number, value: string) =>
              value == undefined ||
              (value.match(this.format) || []).length >= minSpecialSigns ||
              this.$t('PasswordRules.HasEnoughSpecialSigns', { minSpecialSigns: minSpecialSigns })
        }
      },
    },
    methods:{
      PerformAuthenticationProcedure () {
          if(this.onlyCardNumberEntered){
            this.CheckCardNumber();
            this.CheckPasswordState();
            this.cardNumberEntered = true;
            return;
          }
          if(this.startLogin){
            this.Login();
            return;
          }
          if(this.startRegister){
            this.Register();
            return;
          }
          this.ProcedureFailed();
      },
      CheckCardNumber () {
        let url = this.$config.GatewayUrl + '/api/v1/issuer/checkCard?cardNumber='+this.credentialModel.CardNumber;
        AuthenticationRequests.CheckCardNumber(url, this.credentialModel);
      },
      CheckPasswordState () {
        let url = `${this.$config.GatewayUrl}/api/v1/issuer/checkPasswordState?cardNumber=${this.credentialModel.CardNumber}`;
        AuthenticationRequests.CheckPasswordState(url, this.credentialModel);
      },
      Login () {
        let url = this.$config.GatewayUrl + '/api/v1/issuer/login';
        AuthenticationRequests.Login(url, this.credentialModel);
      },
      Register () {
        let url = this.$config.GatewayUrl + '/api/v1/issuer/register';
        AuthenticationRequests.Register(url, this.credentialModel);
      },
      ProcedureFailed () {
          this.showError = true
          setTimeout(() => {
            this.showError = false
          }, 5000)
      },
      LoginSuccessful (data : any) {
        this.$cookies.set("token", data, this.cookieSettings.livetime, "",
            "", this.cookieSettings.secure, this.cookieSettings.sameSite);
        this.credentialModel.Reset();
        this.$emit('logged-in');
      },
      Reset(){
        this.credentialModel.Reset();
      }
    },
  watch:{
    'credentialModel.Token' : function () {
      if(this.credentialModel.Token != '' && !this.credentialModel.IsReset){
        this.LoginSuccessful(this.credentialModel.Token);
      }
    },
    'credentialModel.CardExists' : function (){
      if(!this.credentialModel.CardExists && !this.credentialModel.IsReset){
        this.ProcedureFailed();
        this.credentialModel.Reset();
      }
    },
    'credentialModel.CredentialError' : function (){
      if(this.credentialModel.CredentialError != CredentialServiceError.None && !this.credentialModel.IsReset){
        this.ProcedureFailed();
        this.credentialModel.Reset();
      }
    }
  }
});
