export class IssuerCardImportDto {
    public successfulImports: number | undefined = undefined;
    public failedImports: number | undefined = undefined;
    public incorrectLinesAsCsv:any | undefined = undefined;
    public hasResult:boolean = false;
    public hasCsvValidationError:boolean = false
    public csvValidationError:string = ""; 
    
    public SetValues(model:IssuerCardImportDto, hasResult: boolean){
        this.successfulImports = model.successfulImports;
        this.failedImports = model.failedImports;
        this.incorrectLinesAsCsv = model.incorrectLinesAsCsv;
        this.hasCsvValidationError = false;
        this.csvValidationError = "";
        this.hasResult = hasResult;
    }
    
    public SetError(csvValidationError:string){
        this.csvValidationError = csvValidationError;
        this.hasCsvValidationError = true;
    }
}