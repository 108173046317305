
import Vue from "vue";
import Account from "./Account.vue";
import PaycardsOverview from "./PaycardsOverview.vue";
import {PersonalizedLogo} from "@/models/PersonalizedLogo";
import {IssuerCardsRequests} from "@/scripts/ProfiControlRequests/IssuerCardsRequests";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default Vue.extend({
  components: {
    BaseButton,
    PaycardsOverview,
    Account,
  },
  data: () => ({
    startViewActive: true,
    accountViewActive: false,
    personalizedLogoModel: new PersonalizedLogo(),
  }),
  computed: {
    hasPersonalizedIssuerLogo():boolean {
      return Boolean(this.personalizedLogoModel.PersonalizedLogo?.Data);
    }
  },
  created: function () {
    this.getPersonalizedLogo();
  },
  methods: {
    toggleAccountView() {
      this.startViewActive = !this.startViewActive;
      this.accountViewActive = !this.accountViewActive;
    },
    logout() {
      if (this.$cookies.isKey("token")) {
        this.$cookies.remove("token");
      }
      this.$emit("logged-out");
    },
    getPersonalizedLogo() {
      let url = `${this.$config.GatewayUrl}/api/v1/personalized/logo`;
      let token = this.$cookies.get('token');
      IssuerCardsRequests.GetPersonalizedLogo(url, token, this.personalizedLogoModel);
    },
  }
});
