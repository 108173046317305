
import Vue from "vue";
import AppBar from './components/AppBar.vue';
import Login from './components/Login.vue';
import Start from './components/Start.vue';
import { View } from "./Enums";
import '@/assets/css/global/google_fonts/roboto.css'
import '@/assets/css/global/materialdesign/icons/materialdesignicons.min.css'


export default Vue.extend({
  name: 'App',

  components: {
    AppBar,
    Login,
    Start,
  },
  data: () => ({
    showError: false,
    View,
    CurrentView: View.Login,
  }),
  created(){
    this.SetViewOnStart();
    if(localStorage.darkMode) {
      //boolean Werte werden nicht als Typ boolean abgelegt
      this.$vuetify.theme.dark = JSON.parse(localStorage.darkMode) === true;
    }
    if(localStorage.language) {
      this.$i18n.locale = localStorage.language;
    }
  },
  methods: {
    CookieIsSet: function () : boolean {
      return this.$cookies.isKey("token");
    },
    SetViewOnStart: function () {
      if(!this.$cookies.isKey("token")){
        this.CurrentView = View.Login;
        return;
      }
      this.CurrentView = View.Start;
    },
    LoggedIn: function () {
      this.CurrentView = View.Start;
    },
    LoggedOut: function () {
      this.CurrentView = View.Login;
    },
    ChangeLanguage: function(language: string){
      this.$i18n.locale = language;
    }
  },
});
