import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('div',[_c('h1',{staticClass:"heading"},[_vm._v(_vm._s(_vm.$t('AccountHeading')))]),_c('div',[(_vm.personalizedLogoModel.PersonalizedLogo != undefined)?_c('LogoEdit',{ref:"PaycardLogo",attrs:{"label":_vm.$t('LogoEdit'),"model":_vm.personalizedLogoModel.PersonalizedLogo,"dpi":_vm.dpi,"maximumHeight":_vm.maximumImageHeight,"maximumWidth":_vm.maximumImageWidth,"HasNoEditRights":false},on:{"uploadSuccessful":_vm.UpdatePersonalizedLogo}}):_vm._e(),_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('PasswordChangeCard.Heading')))]),_c('p',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.$t('PasswordChangeCard.Information')))]),_c(VForm,{model:{value:(_vm.passwordRules.Valid),callback:function ($$v) {_vm.$set(_vm.passwordRules, "Valid", $$v)},expression:"passwordRules.Valid"}},[_c(VTextField,{staticClass:"text-field space-bot space-top",attrs:{"label":_vm.$t('PasswordChangeCard.CurrentPassword'),"append-icon":_vm.showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showCurrentPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showCurrentPassword = !_vm.showCurrentPassword}},model:{value:(_vm.credentialModel.CurrentPassword),callback:function ($$v) {_vm.$set(_vm.credentialModel, "CurrentPassword", $$v)},expression:"credentialModel.CurrentPassword"}}),_c(VTextField,{staticClass:"text-field",attrs:{"counter":"20","rules":[
                _vm.rules.required,
                _vm.rules.max(_vm.passwordRules.MaxLength, _vm.credentialModel.NewPassword),
                _vm.rules.min(_vm.passwordRules.MinLength, _vm.credentialModel.NewPassword),
                _vm.rules.enoughNumbers(_vm.passwordRules.MinCountEnoughNumbers, _vm.credentialModel.NewPassword),
                _vm.rules.enoughUpperCases(_vm.passwordRules.MinCountUpperChars, _vm.credentialModel.NewPassword),
                _vm.rules.enoughLowerCases(_vm.passwordRules.MinCountLowerChars, _vm.credentialModel.NewPassword),
                _vm.rules.enoughSpecialSigns(_vm.passwordRules.MinCountSpecialSigns, _vm.credentialModel.NewPassword),
                _vm.rules.sameValue(_vm.credentialModel.NewPassword, _vm.credentialModel.NewPasswordConfirm),
              ],"label":_vm.$t('PasswordChangeCard.NewPassword'),"append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNewPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.credentialModel.NewPassword),callback:function ($$v) {_vm.$set(_vm.credentialModel, "NewPassword", $$v)},expression:"credentialModel.NewPassword"}}),_c(VTextField,{staticClass:"text-field",attrs:{"counter":"20","rules":[
                _vm.rules.required,
                _vm.rules.max(_vm.passwordRules.MaxLength, _vm.credentialModel.NewPasswordConfirm),
                _vm.rules.min(_vm.passwordRules.MinLength, _vm.credentialModel.NewPasswordConfirm),
                _vm.rules.enoughNumbers(_vm.passwordRules.MinCountEnoughNumbers, _vm.credentialModel.NewPasswordConfirm),
                _vm.rules.enoughUpperCases(_vm.passwordRules.MinCountUpperChars, _vm.credentialModel.NewPasswordConfirm),
                _vm.rules.enoughLowerCases(_vm.passwordRules.MinCountLowerChars, _vm.credentialModel.NewPasswordConfirm),
                _vm.rules.enoughSpecialSigns(_vm.passwordRules.MinCountSpecialSigns, _vm.credentialModel.NewPasswordConfirm),
                _vm.rules.sameValue(_vm.credentialModel.NewPassword, _vm.credentialModel.NewPasswordConfirm),
              ],"label":_vm.$t('PasswordChangeCard.RepeatNewPassword'),"append-icon":_vm.showNewPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNewPasswordRepeat ? 'text' : 'password'},on:{"click:append":function($event){_vm.showNewPasswordRepeat = !_vm.showNewPasswordRepeat}},model:{value:(_vm.credentialModel.NewPasswordConfirm),callback:function ($$v) {_vm.$set(_vm.credentialModel, "NewPasswordConfirm", $$v)},expression:"credentialModel.NewPasswordConfirm"}}),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"align":"center"}},[(_vm.showError)?_c(VAlert,{staticClass:"response-box",attrs:{"type":"error"}},[_c('p',[_vm._v(_vm._s(_vm.$t('ErrorHeader')))]),_vm._v(" "+_vm._s(_vm.$t('Error'))+" ")]):_vm._e()],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"align":"center"}},[(_vm.showSuccess)?_c(VAlert,{staticClass:"response-box",attrs:{"type":"success"}},[_c('p',[_vm._v(_vm._s(_vm.$t('SuccessHeader')))]),_vm._v(" "+_vm._s(_vm.$t('Success'))+" ")]):_vm._e()],1)],1),_c(VCardActions,[_c(VRow,{attrs:{"align":"center"}},[_c('BaseButton',{attrs:{"cssClass":"setting-buttons","buttonText":_vm.$t('PasswordChangeCard.SaveButton'),"backgroundColor":_vm.$config.Design.AccentColor1,"textColor":'color:' + _vm.$config.Design.TextColor1,"isDisabled":_vm.passwordValidation},on:{"click":_vm.SavePassword}})],1)],1)],1)],1),_c(VSpacer),_c(VRow,{attrs:{"align":"center"}},[_c('BaseButton',{attrs:{"cssClass":"setting-buttons","buttonText":_vm.$t('PasswordChangeCard.BackButton'),"backgroundColor":_vm.$config.Design.AccentColor1,"textColor":'color:' + _vm.$config.Design.TextColor1,"isDisabled":false},on:{"click":_vm.Back}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }