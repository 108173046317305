
import Vue from "vue";

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: false,
      default: undefined
    },
    prependIcon: {
      type: String,
      required: false,
      default: undefined
    },
    appendIcon: {
      type: String,
      required: false,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: false,
      default: undefined
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined
    },
    cssClass: {
      type: String,
      required: false,
    }
  },
  computed: {
    defaultBackground(): string {
      return this.$config.Design.AccentColor1
    },
    defaultTextColor(): string {
      return `color: ${this.$config.Design.TextColor1}`
    }
  },
  methods: {
    Clicked: function () {
      this.$emit("click");
    },
  }
})
