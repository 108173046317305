<template>
    <v-app-bar app>
        <div class="d-flex align-center">
            <v-img
                alt="Logo"
                class="shrink mr-2"
                contain
                src="@/assets/images/logo-appbar.png"
                transition="scale-transition"
                width="40"
            />
            <v-toolbar-title>{{$t('Title')}}</v-toolbar-title>
        </div>

        <v-spacer></v-spacer>
        <v-btn text @click="ChangeLanguage('de')">DE</v-btn>
        <v-btn text @click="ChangeLanguage('en')">EN</v-btn>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="ChangeDarkMode()"
                >
                    <v-icon>
                        {{
                        $vuetify.theme.dark
                            ? "mdi-water"
                            : "mdi-water"
                        }}
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $t("DarkMode") }}</span>
        </v-tooltip>
    </v-app-bar>
</template>

<script>

import Vue from "vue";
export default Vue.extend({
    name: "AppBar",
    methods: {
      ChangeDarkMode() {
        localStorage.darkMode = !this.$vuetify.theme.dark;
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      },
      ChangeLanguage(language){
        localStorage.language = language;
        this.$emit('changeLanguage', language);
      }
    }
})

</script>

<i18n src="@/assets/locales/i18n-appbar.json"></i18n>