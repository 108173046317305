import { LogoDto } from "./LogoDto";

export class Logo {
    private imageModel: LogoDto;

    public Format: string | undefined = '';
    public Data: string | undefined = '';

    constructor(image: LogoDto) {
        this.imageModel = image;
        this.Format = this.imageModel.format;
        this.Data = this.imageModel.data;
    } 
    
    public hasChanges(){
        return this.Format != this.imageModel.format || this.Data != this.imageModel.data;
    }
}
