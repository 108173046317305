import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"align-row"},[_c(VCard,{staticClass:"loginDialog"},[_c(VCardTitle,{staticClass:"headline heading"},[_vm._v(_vm._s(_vm.$t('Login')))]),_c(VImg,{staticClass:"mx-auto",attrs:{"alt":"Logo","src":require("@/assets/images/logo-login.png"),"transition":"scale-transition","width":"50%"}}),_c(VForm,{model:{value:(_vm.passwordRules.Valid),callback:function ($$v) {_vm.$set(_vm.passwordRules, "Valid", $$v)},expression:"passwordRules.Valid"}},[_c(VTextField,{staticClass:"text-field space-bot space-top",attrs:{"color":this.$config.Design.AccentColor1,"label":_vm.$t('CardNumber')},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.PerformAuthenticationProcedure.apply(null, arguments)}},model:{value:(_vm.credentialModel.CardNumber),callback:function ($$v) {_vm.$set(_vm.credentialModel, "CardNumber", $$v)},expression:"credentialModel.CardNumber"}}),(!_vm.credentialModel.PasswordIsSet)?_c(VTextField,{directives:[{name:"show",rawName:"v-show",value:(_vm.cardNumberEntered && _vm.credentialModel.CardExists),expression:"cardNumberEntered && credentialModel.CardExists"}],staticClass:"text-field",attrs:{"counter":_vm.passwordRules.MaxLength,"rules":[
                      _vm.rules.required,
                      _vm.rules.max(_vm.passwordRules.MaxLength, _vm.credentialModel.NewPassword),
                      _vm.rules.min(_vm.passwordRules.MinLength, _vm.credentialModel.NewPassword),
                      _vm.rules.enoughNumbers(_vm.passwordRules.MinCountEnoughNumbers, _vm.credentialModel.NewPassword),
                      _vm.rules.enoughUpperCases(_vm.passwordRules.MinCountUpperChars, _vm.credentialModel.NewPassword),
                      _vm.rules.enoughLowerCases(_vm.passwordRules.MinCountLowerChars, _vm.credentialModel.NewPassword),
                      _vm.rules.enoughSpecialSigns(_vm.passwordRules.MinCountSpecialSigns, _vm.credentialModel.NewPassword),
                      _vm.rules.sameValue(_vm.credentialModel.NewPassword, _vm.credentialModel.NewPasswordConfirm)
                    ],"color":this.$config.Design.AccentColor1,"label":_vm.$t('Password'),"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Login.apply(null, arguments)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.credentialModel.NewPassword),callback:function ($$v) {_vm.$set(_vm.credentialModel, "NewPassword", $$v)},expression:"credentialModel.NewPassword"}}):_c(VTextField,{directives:[{name:"show",rawName:"v-show",value:(_vm.cardNumberEntered && _vm.credentialModel.CardExists),expression:"cardNumberEntered && credentialModel.CardExists"}],staticClass:"text-field",attrs:{"color":this.$config.Design.AccentColor1,"label":_vm.$t('Password'),"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Login.apply(null, arguments)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.credentialModel.CurrentPassword),callback:function ($$v) {_vm.$set(_vm.credentialModel, "CurrentPassword", $$v)},expression:"credentialModel.CurrentPassword"}}),_c(VTextField,{directives:[{name:"show",rawName:"v-show",value:(_vm.cardNumberEntered && _vm.credentialModel.CardExists && !_vm.credentialModel.PasswordIsSet),expression:"cardNumberEntered && credentialModel.CardExists && !credentialModel.PasswordIsSet"}],staticClass:"text-field",attrs:{"counter":_vm.passwordRules.MaxLength,"rules":[
                      _vm.rules.required,
                      _vm.rules.max(_vm.passwordRules.MaxLength, _vm.credentialModel.NewPasswordConfirm),
                      _vm.rules.min(_vm.passwordRules.MinLength, _vm.credentialModel.NewPasswordConfirm),
                      _vm.rules.enoughNumbers(_vm.passwordRules.MinCountEnoughNumbers, _vm.credentialModel.NewPasswordConfirm),
                      _vm.rules.enoughUpperCases(_vm.passwordRules.MinCountUpperChars, _vm.credentialModel.NewPasswordConfirm),
                      _vm.rules.enoughLowerCases(_vm.passwordRules.MinCountLowerChars, _vm.credentialModel.NewPasswordConfirm),
                      _vm.rules.enoughSpecialSigns(_vm.passwordRules.MinCountSpecialSigns, _vm.credentialModel.NewPasswordConfirm),
                      _vm.rules.sameValue(_vm.credentialModel.NewPassword, _vm.credentialModel.NewPasswordConfirm),
                    ],"color":this.$config.Design.AccentColor1,"label":_vm.$t('RepeatPassword'),"append-icon":_vm.showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPasswordRepeat ? 'text' : 'password'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Login.apply(null, arguments)},"click:append":function($event){_vm.showPasswordRepeat = !_vm.showPasswordRepeat}},model:{value:(_vm.credentialModel.NewPasswordConfirm),callback:function ($$v) {_vm.$set(_vm.credentialModel, "NewPasswordConfirm", $$v)},expression:"credentialModel.NewPasswordConfirm"}}),_c('TextButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.credentialModel.CardExists),expression:"credentialModel.CardExists"}],staticClass:"not-your-card-button",attrs:{"buttonText":_vm.$t('ResetButton'),"isDisabled":false},on:{"click":_vm.Reset}}),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"align-row"},[(_vm.showError)?_c(VAlert,{staticClass:"error-box",attrs:{"color":"red","type":"error"}},[_c('p',[_vm._v(_vm._s(_vm.$t('ErrorHeader')))]),_vm._v(" "+_vm._s(_vm.$t('Error'))+" ")]):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"cssClass":"login-button","buttonText":_vm.$t('Ok'),"backgroundColor":_vm.$config.Design.AccentColor1,"textColor":'color:' + _vm.$config.Design.TextColor1,"isDisabled":_vm.continueValidation},on:{"click":_vm.PerformAuthenticationProcedure}}),_c(VSpacer)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }