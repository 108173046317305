import {CrudManager} from "@/scripts/ProfiControlRequests/CrudManager";
import moment from "moment";
import {DateFormats} from "@/scripts/Common/DateFormats";
import {FileDownload} from "@/scripts/Common/FileDownload";

export class ExportRequests{

    public static CardConfiguration(url:string, token:string, exportName:string, language:string) : void
    {
        CrudManager.Get(url, token, language)
            .then((response) => {
                if (!!response.data) {
                    exportName += `_${moment().format(DateFormats.YEAR_MONTH_DAY)}.csv`;
                    FileDownload.Create(response.data, exportName);
                }
            })
            .catch();
    }
    
}