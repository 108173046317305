import { render, staticRenderFns } from "./LogoEdit.vue?vue&type=template&id=ba24a1a6&scoped=true"
import script from "./LogoEdit.vue?vue&type=script&lang=ts"
export * from "./LogoEdit.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/scoped/logo-edit-style.css?vue&type=style&index=0&id=ba24a1a6&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba24a1a6",
  null
  
)

/* custom blocks */
import block0 from "@/assets/locales/i18n-logo-edit.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2FLogoEdit.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports