import {DateFormats} from "@/scripts/Common/DateFormats";
import moment from "moment";

export class HelperFunctions {

    // Laden des letzten Tages des Monats über angabe der 0 wird dieser automatisch geholt.
    public static getLastDayOfMonth(year: number, month: number): number {
        return new Date(year, month, 0).getDate()
    }

    public static isEmpty(str: string | undefined): boolean {
        return (!str || str.length === 0);
    }

    public static formatDate(dateAsString: string, language: string): string {
        try {
            const parsedDate = Date.parse(dateAsString);
            return new Intl.DateTimeFormat(language, DateFormats.LANGUAGE_OPTIONS).format(parsedDate);
        } catch (_) {
            return "";
        }
    }

    public static GetDecimalsCount(value: number): number {
        if (Number.isInteger(value)) return 0;

        const [_, last] = value.toString().split('.')
        return last?.length ?? 0;
    }
}

const debounce = (fn: Function, ms = 500) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

const getCurrentDate = (format: string) => moment().format(format);

const isEndDateAfterOrEqualToStartDate = (startDate: string, endDate: string) => {
    return new Date(endDate).getTime() >= new Date(startDate).getTime()
}

export {
    debounce,
    getCurrentDate,
    isEndDateAfterOrEqualToStartDate
}
