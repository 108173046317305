
import Vue from "vue";
import FloatingActionButton from "@/components/buttons/FloatingActionButton.vue";
import InformationDialog from "@/components/base/InformationDialog.vue";
import ConfirmationDialog from "@/components/base/ConfirmationDialog.vue";
import {HelperFunctions} from "@/scripts/Common/HelperFunctions";
import {Logo} from "@/models/Logo";
import {ImageDownload} from "@/scripts/Common/ImageDownload";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default Vue.extend({
  components: {BaseButton, ConfirmationDialog, InformationDialog, FloatingActionButton},
  props: {
    label: String,
    isdummy: Boolean,
    model: Logo,
    dpi: Number,
    maximumWidth: Number,
    maximumHeight: Number,
    ignoreValidation: Boolean,
    isRequired: Boolean,
    HasNoEditRights: Boolean
  },
  data: function () {
    return {
      isSelecting: false,
      dialogFormat: false,
      dialogFormatSaveQuestion: false,
      dialogFormatMessage: "",
      imageMetaData: {
        height: "",
        width: "",
      },
      tempExtension: "",
      tempImageData: ""
    };
  },
  computed: {
    heightLabel: function (): string {
      return !!this.maximumHeight
          ? this.$t("RequiredHeight").toString()
          : this.$t("Height").toString();
    },
    widthLabel: function (): string {
      return !!this.maximumWidth
          ? this.$t("RequiredWidth").toString()
          : this.$t("Width").toString();
    },
    height: function (): string {
      return !!this.maximumHeight
          ? this.maximumHeight.toString() + " px"
          : !!this.model.Data
              ? this.imageMetaData.height
              : "";
    },
    width: function (): string {
      return !!this.maximumWidth
          ? this.maximumWidth.toString() + " px"
          : !!this.model.Data
              ? this.imageMetaData.width
              : "";
    },
    isValid: function (): boolean {
      return !this.isRequired ||
          (this.isRequired &&
              !HelperFunctions.isEmpty(this.model.Data) &&
              !HelperFunctions.isEmpty(this.model.Format));
    }
  },
  methods: {
    updateLogo() {
      this.$emit("uploadSuccessful");
    },
    uploadImage() {
      this.model.Data = this.tempImageData;
      this.model.Format = this.tempExtension;
      this.dialogFormatSaveQuestion = false;
    },
    setLogo(file: File) {
      let classContext = this;
      let reader = new FileReader();
      let extension = file.name.split(".").pop()?.toLowerCase();
      reader.readAsDataURL(file);

      reader.onload = function () {
        if (!!reader.result) {
          let image = new Image();
          image.src = reader.result as string;
          image.onload = function () {
            classContext.ProcessImageCompressions(image, extension as string)
          };

          return;
        }
      };
      reader.onerror = function () {
        classContext.dialogFormatMessage = classContext.$t("Dialog.UnknownError").toString();
        classContext.ShowErrorDialog();
      };
    },
    ProcessImageCompressions(image: HTMLImageElement, extension: string) {
      if (this.HasImageDesiredFormat(image)) {
        this.model.Data = image.src;
        this.model.Format = extension;
        return;
      }
      this.GenerateImageError(image, extension);
    },
    GenerateImageError(image: HTMLImageElement, extension: string) {
      this.ignoreValidation
          ? this.GenerateFormatError(image, extension)
          : this.GenerateTypeError(image);
    },
    HasImageDesiredFormat(image: HTMLImageElement) {
      return (!this.maximumWidth || image.width <= this.maximumWidth) &&
          (!this.maximumHeight || image.height <= this.maximumHeight);
    },
    GenerateTypeError(image: HTMLImageElement) {
      this.dialogFormatMessage = this.$t("Dialog.FormatError", {
        desiredWidth: this.maximumWidth,
        desiredHeight: this.maximumHeight,
        height: image.height,
        width: image.width,
      }).toString();
      this.ShowErrorDialog();
    },
    GenerateFormatError(image: HTMLImageElement, extension: string) {
      this.tempImageData = image.src;
      this.tempExtension = extension;

      this.dialogFormatMessage = this.$t(
          "Dialog.FormatErrorSaveQuestion",
          {
            maximumWidth: this.maximumWidth,
            maximumHeight: this.maximumHeight,
            height: image.height,
            width: image.width,
          }
      ).toString();
      this.ShowConfirmationDialog();
    },
    ShowErrorDialog() {
      (this.$refs.errorInformationDialog as any).Show();
    },
    ShowConfirmationDialog() {
      (this.$refs.saveImageErrorDialog as any).Show();
    },
    ShowFileDialog() {
      this.isSelecting = true;
      window.addEventListener("focus", () => {
            this.isSelecting = false;
          },
          {once: true}
      );
      (this.$refs.uploader as any).click();
    },
    Download() {
      if (this.model && this.model.Data) {
        let filename = `${this.label}.${this.model.Format}`;
        ImageDownload.Create(this.model.Data, filename)
      }
    },
    DeleteImage() {
      if (this.model && this.model.Data) {
        this.model.Data = undefined;
        this.model.Format = undefined;
        this.imageMetaData.width = "";
        this.imageMetaData.height = "";
      }
    },
    OnLoadImage(value: string) {
      let image = new Image();
      image.src = value;
      if (!this.dpi) {
        this.imageMetaData.width = image.width + " px";
        this.imageMetaData.height = image.height + " px";
      }
    },
  },
  watch: {
    isValid: function () {
      this.$emit("ValidChanged", this.isValid);
    }
  }
  
});
