import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const  dateTimeFormats = {
  'en': {
    short: {
      month: 'numeric', day: 'numeric', year: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'de': {
    short: {
      day: 'numeric', month: 'numeric',  year: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentFallbackWarn: true,
  dateTimeFormats
})
