<template>
  <v-btn
      color="blue darken-1 accent-1"
      text
      :disabled="isDisabled"
      @click="Clicked"
  >
    {{buttonText}}
  </v-btn>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    Clicked: function () {
      this.$emit("click");
    },
  },
})
</script>
<style scoped src="@/assets/css/scoped/button-style.css"></style>
