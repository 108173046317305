
import Vue from "vue";
import IconTextButton from "@/components/buttons/IconTextButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import {HelperFunctions, isEndDateAfterOrEqualToStartDate} from "@/scripts/Common/HelperFunctions";
import moment from "moment/moment";
import {DateFormats} from "@/scripts/Common/DateFormats";

export default Vue.extend({
  components: {
    BaseButton
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isActive: false,
    isValid: false,
    startDate: "",
    endDate: "",
    isBusy: false
  }),
  computed: {
    rules() {
      return {
        required: (value: string) => !!value || this.$t("Rules.Required"),
        contractEndAfterStart: (endDate: string) =>
            endDate === "" ||
            this.startDate === "" ||
            isEndDateAfterOrEqualToStartDate(this.startDate, endDate) ||
            this.$t("Rules.ContractEndValidation", {startDate: moment(this.startDate).format(DateFormats.ISO8601_LONG)})
      };
    },
  },
  methods: {
    show: function () {
      this.isActive = true;
    },
    hide: function () {
      this.isActive = false;
    },
    cancel: function () {
      this.hide();
      this.$emit("cancelled");
    },
    confirm: function () {
      this.hide();
      this.$emit("confirmed", {startDate: this.startDate, endDate: this.endDate});
    },
    validateDialogForm() {
      (this.$refs.dialogForm as any).validate()
    }
  },
});
