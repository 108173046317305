import {IssuerCardDto} from "./IssuerCardDto";
import {TopUpRotation, WebChargeStatus} from "@/Enums";
import {Moment} from "moment";
import {HelperFunctions} from "@/scripts/Common/HelperFunctions";
import {CardLimite} from "@/models/CardLimite";

export class IssuerCard {
    private issuerCard: IssuerCardDto;
    public Amount: number | undefined = undefined;
    public Rotation: TopUpRotation = TopUpRotation.None;
    public RotationStart: string | undefined = undefined;
    public RotationEnd: string | undefined = undefined;
    public Reference: string | undefined = undefined;

    public IsActive: boolean = true;
    public ChargeDate: Moment | undefined = undefined;
    public ChargeStatus: WebChargeStatus = WebChargeStatus.Unknown;
    public LastChargeProtocolId: number = 0;

    public readonly ReferenceMaxLength: number = 255;
    public readonly MaximumDecimals = 2;

    constructor(issuerCard?: IssuerCardDto) {
        this.issuerCard = !!issuerCard
            ? issuerCard
            : new IssuerCardDto();
        this.issuerCard.rotationStart = this.issuerCard.rotationStart?.substr(0, 10);
        this.issuerCard.rotationEnd = this.issuerCard.rotationEnd?.substr(0, 10);
        this.Amount = this.issuerCard.amount;
        this.Rotation = this.issuerCard.rotation;
        this.RotationStart = this.issuerCard.rotationStart;
        this.RotationEnd = this.issuerCard.rotationEnd;
        this.Reference = this.issuerCard.reference;
    }
    
    get CardNumber() {
        return this.issuerCard.cardNumber;
    }

    get HasError() {
        return this.ChargeStatus !== WebChargeStatus.Success
            && this.ChargeStatus !== WebChargeStatus.Unknown;
    }

    get HasChanges() {
        return this.issuerCard.id === undefined ||
            this.Amount?.toString() !== this.issuerCard.amount?.toString() ||
            this.Rotation !== this.issuerCard.rotation ||
            this.RotationStart !== this.issuerCard.rotationStart ||
            this.RotationEnd !== this.issuerCard.rotationEnd ||
            (this.Reference === null ? '' : this.Reference) !== (this.issuerCard.reference === null ? '' : this.issuerCard.reference);
    }

    get HasOnlyReferenceChanges():boolean {
        return this.Amount?.toString() === this.issuerCard.amount?.toString() &&
            this.Rotation === this.issuerCard.rotation &&
            this.RotationStart === this.issuerCard.rotationStart &&
            this.RotationEnd === this.issuerCard.rotationEnd
    }
    
    get HasOnlyRotationEndChanges() {
        return this.Amount?.toString() === this.issuerCard.amount?.toString() &&
            this.Rotation === this.issuerCard.rotation &&
            this.RotationStart === this.issuerCard.rotationStart &&
            (this.Reference === null ? '' : this.Reference) === (this.issuerCard.reference === null ? '' : this.issuerCard.reference) &&
            this.RotationEnd !== this.issuerCard.rotationEnd;

    }

    public FormattedRotationStart(language: string) {
        if (!this.RotationStart) return null;
        return HelperFunctions.formatDate(this.RotationStart, language);
    }

    public FormattedRotationEnd(language: string) {
        if (!this.RotationEnd) return null;
        return HelperFunctions.formatDate(this.RotationEnd, language);
    }

    public RemoveStartDate() {
        this.RotationStart = undefined;
    }

    public RemoveEndDate() {
        this.RotationEnd = undefined;
    }

    public GetModel(): IssuerCardDto {
        let model = new IssuerCardDto();

        model.id = this.issuerCard.id;
        model.issuerId = this.issuerCard.issuerId;
        model.cardNumber = this.issuerCard.cardNumber;
        model.amount = this.Amount;
        model.rotation = this.Rotation;
        model.rotationStart = this.RotationStart;
        model.rotationEnd = this.RotationEnd;
        model.reference = this.Reference;

        return model;
    }

    public HasValidRoationEnd(): boolean {
        let isValid = true;

        //Check if model has no changes, rotation is none or rotation end is not set
        if (!this.HasChanges || this.Rotation === TopUpRotation.None || !this.RotationEnd) return isValid;

        let now = new Date().getTime();
        let configuredRotationEnd = new Date(this.RotationEnd).getTime();
        let configuredRotationStart = !!this.RotationStart ? new Date(this.RotationStart).getTime() : undefined;

        //check if roationEnd is bigger as now and bigger as rotationStart
        isValid = now < configuredRotationEnd &&
            (!!configuredRotationStart && configuredRotationStart < configuredRotationEnd);

        return isValid;
    }

    public HasValidRoationStart(): boolean {
        let isValid = true;

        //Check if model has no changes or rotation is none
        if (!this.HasChanges || this.Rotation === TopUpRotation.None) return isValid;
        //if rotation is not none a values need to be set
        if (!this.RotationStart) return false;
        //if has only rotation end changes
        if (this.HasOnlyRotationEndChanges) return isValid;
        //if has only reference changes
        if(this.HasOnlyReferenceChanges) return isValid;
        
        //check if rotation start is in future
        isValid = new Date().getTime() < new Date(this.RotationStart).getTime();
        return isValid;
    }

    //Rotations to differ card limites
    public readonly restrictedRotations: Array<TopUpRotation> =
        [TopUpRotation.Monthly, TopUpRotation.Quarterly, TopUpRotation.Yearly];

    public HasValidAmount(limite: CardLimite): boolean {
        //Rotation None is allowed without an Amount
        if ((!this.Amount || this.Amount === 0) && this.Rotation === TopUpRotation.None) return true;

        //Else there must be a value
        if (!this.Amount) return false;

        //Check if amount is smaller or equal than 0
        if (this.Amount <= 0) return false;

        //Check if decimals are more than allowed decimals
        if (HelperFunctions.GetDecimalsCount(this.Amount) > this.MaximumDecimals) return false;

        //Check if amount is bigger than max card limite
        if (this.Amount >= limite.maxCardLimit) return false;

        //Check if amount is bigger than top up limit per month
        if (this.Amount >= limite.topUpLimitPerMonth) return false;

        //Check if amount is bigger than maxMonthlyTopUp
        if (this.restrictedRotations.includes(this.Rotation)
            && this.Amount > limite.maxMonthlyTopUpAmount)
            return false;

        return true;
    }

    public HasValidReference() {
        return !this.Reference || this.Reference.length <= this.ReferenceMaxLength;
    }

    public Reset() {
        this.Amount = this.issuerCard.amount;
        this.Rotation = this.issuerCard.rotation;
        this.RotationStart = this.issuerCard.rotationStart;
        this.RotationEnd = this.issuerCard.rotationEnd;
    }
}
