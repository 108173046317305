

import { ExportPaycards } from "@/models/ExportPaycards";
import Vue from "vue";
import {downloadReport} from "@/scripts/ProfiControlRequests/ReportRequests";
import BaseButton from "@/components/buttons/BaseButton.vue";
import {ReportDownloadModel} from "@/models/ReportDownloadModel";

export default Vue.extend({
  components: {
    BaseButton,
  },
  props: {
    model: ExportPaycards
  },
  data: () => ({
    deadline: "",
  }),
  computed: {
    token(): string {
      return this.$cookies.get('token');
    }
  },
  methods: {
    Confirm() {
      this.model.ShowDialog = false;
      this.Export();
    },
    Export() {
      let url = `${this.$config.GatewayUrl}/api/v1/issuer-reports/card-report/${this.deadline}`;
      const reportName = this.$t("CardReportBaseName").toString();
      let fileModel = new ReportDownloadModel(reportName, this.deadline);
      downloadReport(url, this.token, fileModel);
    }
  },
});
