import {Logo} from "@/models/Logo";
import {PersonalizedLogoDto} from "@/models/PersonalizedLogoDto";

export class PersonalizedLogo {
    
    private personalizedPaycardModel : PersonalizedLogoDto;
    public PersonalizedLogoId: number | undefined = undefined;
    public PersonalizedLogo: Logo  | undefined = undefined;

    constructor(personalizedPaycard? : PersonalizedLogoDto) {
        this.personalizedPaycardModel = !!personalizedPaycard
            ? personalizedPaycard
            : new PersonalizedLogoDto();
        this.setValues(this.personalizedPaycardModel);
    }
    
    public setValues(personalizedPaycard? : PersonalizedLogoDto){
        this.personalizedPaycardModel = !!personalizedPaycard
            ? personalizedPaycard
            : new PersonalizedLogoDto();
        this.PersonalizedLogoId = this.personalizedPaycardModel.personalizedLogoId;
        this.PersonalizedLogo = this.personalizedPaycardModel.personalizedLogo == null
            ? undefined
            : new Logo(this.personalizedPaycardModel.personalizedLogo);
    }
    
    public hasChanges(){
        return this.PersonalizedLogo?.hasChanges();
    }
}