export class CardLimite {
    public maxCardLimit: number = 250;
    public topUpLimitPerMonth: number = 250;
    public maxMonthlyTopUpAmount: number = 50;

    constructor(cardLimite?:CardLimite) {
        this.SetValues(cardLimite);
    }

    public SetValues(cardLimite?:CardLimite){
        this.maxCardLimit = cardLimite?.maxCardLimit ?? 250;
        this.maxMonthlyTopUpAmount = cardLimite?.maxMonthlyTopUpAmount ?? 50;
        this.topUpLimitPerMonth = cardLimite?.topUpLimitPerMonth ?? 250;
    }
}
