import axios from "axios";
import {HeaderBuilder} from "@/scripts/Common/HeaderBuilder";
export class CrudManager{

    public static Get<Type>(url:string, token?:string, language?:string, contentType?:string) {
        if(!!token){
            return axios.get<Type>(url, HeaderBuilder.Build(token, language, contentType));
        }
        return axios.get<Type>(url);
    }

    public static Post<Type>(url:string, data?:any, token?:string, language?:string, contentType?:string){
        if(!!token){
            return axios.post<Type>(url, data, HeaderBuilder.Build(token, language, contentType));
        }
        return axios.post<Type>(url, data);
    }

    public static Put<Type>(url:string, data?:any, token?:string, language?:string, contentType?:string){
        if(!!token){
            return axios.put<Type>(url, data, HeaderBuilder.Build(token, language, contentType));
        }
        return axios.put<Type>(url, data);
    }


    public static Delete<Type>(url:string, token?:string, language?:string, contentType?:string){
        if(!!token){
            return axios.delete<Type>(url, HeaderBuilder.Build(token, language, contentType));
        }
        return axios.delete<Type>(url);
    }
}