import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.backgroundColor,"dark":""}},[_c(VCardTitle,{staticClass:"headline",style:(_vm.textColor)},[_vm._v(_vm._s(_vm.Headline))])],1),_c(VCardText,{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.Content)+" ")]),_c(VFileInput,{staticClass:"mr-15 ml-15",attrs:{"counter":"","accept":"text/csv","label":"File input","placeholder":"Select your files","prepend-icon":"mdi-file-import","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function({ index, text }){return [(index < 1)?_c(VChip,{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"buttonText":_vm.ButtonConfirm,"isDisabled":_vm.HasNoEditRights || !_vm.file,"backgroundColor":_vm.backgroundColor,"textColor":_vm.textColor},on:{"click":_vm.Confirm}}),_c('BaseButton',{attrs:{"buttonText":_vm.ButtonCancel,"backgroundColor":_vm.backgroundColor,"textColor":_vm.textColor,"isDisabled":false},on:{"click":_vm.Cancel}}),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }