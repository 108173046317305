

import Vue from "vue";
import {TopUpRotation} from "@/Enums";
import BaseButton from "@/components/buttons/BaseButton.vue";
import {CardLimite} from "@/models/CardLimite";
import moment from "moment";
import {WebchargeSetting} from "@/models/WebchargeSetting";
import {IssuerCard} from "@/models/IssuerCard";

export default Vue.extend({
  components: {BaseButton},
  data: () => ({
    Valid: true,
    TopUpRotation,
    startDateMenus: false,
    endDateMenus: false,
    isActive: false
  }),
  props: {
    model: IssuerCard,
    cardLimite: CardLimite,
    HasEditRights: Boolean,
    issuerWebchargeSetting: WebchargeSetting
  },
  computed: {
    HasNoEditRights(): boolean {
      return this.HasEditRights == false;
    },
    rules() {
      return {
        requiredCondition: (condition: boolean, value: any) => {
          return (!condition || !!value) ? true : this.$t("Rules.Required");
        },
        validateReference: (value: IssuerCard) => {
          if(value.HasValidReference()) return true;
          return this.$t("Rules.Max", {maxLength: value.ReferenceMaxLength})
        },
        validateStartDate: (value: IssuerCard) => {
          if (value.HasValidRoationStart()) return true;
          return this.$t("Rules.MinDate", {minDate: this.$d(moment().add(1, 'days').toDate(), 'short')});
        },
        validateEndDate: (value: IssuerCard) => {
          if (value.HasValidRoationEnd()) return true;
          return this.$t("Rules.MinDate", {
            minDate: (!value.RotationStart || new Date().getTime() > new Date(value.RotationStart).getTime())
                ? this.$d(moment().add(1, 'days').toDate(), 'short')
                : this.$d(moment(value.RotationStart).add(1, 'days').toDate(), 'short')
          })
        },
        validateAmount: (value: IssuerCard) => {
          if (value.HasValidAmount(this.cardLimite)) return true;
          return value.restrictedRotations.includes(value.Rotation)
              ? this.$t("Rules.AmountBetween", {minValue: 1, maxValue: this.cardLimite.maxMonthlyTopUpAmount, decimals: value.MaximumDecimals})
              : this.$t("Rules.AmountBetween",
                  {
                    minValue: 1,
                    maxValue: this.cardLimite.maxCardLimit > this.cardLimite.topUpLimitPerMonth
                        ? this.cardLimite.topUpLimitPerMonth
                        : this.cardLimite.maxCardLimit,
                    decimals: value.MaximumDecimals})
        },
      };
    },
    rotations(): Array<any> {
      return [
        {id: TopUpRotation.None, value: this.$t("Rotation.None")},
        {id: TopUpRotation.Once, value: this.$t("Rotation.Once")},
        {id: TopUpRotation.Monthly, value: this.$t("Rotation.Monthly")},
        {id: TopUpRotation.Quarterly, value: this.$t("Rotation.Quarterly")},
        {id: TopUpRotation.Yearly, value: this.$t("Rotation.Yearly")},
      ];
    },
  },
  methods: {
    Show: function(){
      this.isActive = true;
    },
    Hide: function (){
      this.isActive = false;
    },
    Confirm() {
      this.Hide();
      this.$emit("Confirmed");
    },
    validateCardForm(){
      //No definition of parameters in documentation
      (this.$refs.AdjustPaycardForm as any).validate()
    }
  },
});
