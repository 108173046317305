import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600"},on:{"click:outside":_vm.Confirm},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.backgroundColor,"dark":""}},[_c(VCardTitle,{staticClass:"headline",style:(_vm.textColor)},[_vm._v(_vm._s(_vm.$t("Dialog.CardImportResult.Headline")))])],1),_c(VCardText,{staticClass:"pa-5"},[_c('div',{staticClass:"ml-1"},[_c(VChip,{staticClass:"ma-2",attrs:{"color":"green","label":"","text-color":"white"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(this.$t("Dialog.CardImportResult.SuccessfulImports", { successfulImports: this.model.successfulImports }))+" ")],1)],1),_c('div',{staticClass:"ml-1"},[_c(VChip,{staticClass:"ma-2",attrs:{"color":"red","label":"","text-color":"white"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(this.$t("Dialog.CardImportResult.RejectedImports", { failedImports: this.model.failedImports }))+" ")],1)],1),_c('div',{staticClass:"mt-2"},[(!!this.model.failedImports && this.model.failedImports > 0)?_c('a',{on:{"click":_vm.DownloadCsv}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t("Dialog.CardImportResult.Link"))+" ")],1):_vm._e()])]),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"buttonText":_vm.$t('Dialog.Buttons.Confirm'),"isDisabled":false,"backgroundColor":_vm.backgroundColor,"textColor":_vm.textColor},on:{"click":_vm.Confirm}}),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }