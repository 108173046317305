import {IssuerCardImportDto} from "@/models/IssuerCardImportDto";
import {CrudManager} from "@/scripts/ProfiControlRequests/CrudManager";

export class ImportRequests{

    public static CardConfigurationImport(
        url:string, 
        token:string, 
        file:any, 
        result:IssuerCardImportDto, 
        language: string
    ) : void
    {
        CrudManager.Put<IssuerCardImportDto>(url, file, token, language,"text/csv")
            .then((response) => {
                result.SetValues(response.data, true);
            })
            .catch((error) => {
                result.SetError(error.response.data) 
            });
    }
    
}