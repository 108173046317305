import { TopUpRotation } from "@/Enums";

export class IssuerCardDto {
    public id: number | undefined = undefined;
    public issuerId: number | undefined = undefined;
    public cardNumber: number = 0;
    public amount: number | undefined = undefined;
    public rotation: TopUpRotation = TopUpRotation.None;
    public rotationStart: string | undefined = undefined;
    public rotationEnd: string | undefined = undefined;
    public reference: string | undefined = undefined;
}