import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.model.ShowDialog),callback:function ($$v) {_vm.$set(_vm.model, "ShowDialog", $$v)},expression:"model.ShowDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.$config.Design.AccentColor1,"dark":""}},[_c(VCardTitle,{staticClass:"headline",style:(`color: ${_vm.$config.Design.TextColor1}`)},[_vm._v(_vm._s(_vm.$t("Dialog.Header"))+" ")])],1),_c(VCardText,[_c(VRow,{staticClass:"pt-10"},[_c(VCol,{attrs:{"cols":"5"}},[_c(VTextField,{attrs:{"type":"date","label":_vm.$t('Deadline')},model:{value:(_vm.deadline),callback:function ($$v) {_vm.deadline=$$v},expression:"deadline"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"cssClass":"setting-buttons-with-mb","buttonText":_vm.$t('Dialog.Ok'),"isDisabled":!_vm.deadline},on:{"click":_vm.Confirm}}),_c('BaseButton',{attrs:{"cssClass":"setting-buttons-with-mb","buttonText":_vm.$t('Dialog.Cancel'),"isDisabled":false},on:{"click":function($event){_vm.model.ShowDialog = false}}}),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }