import { render, staticRenderFns } from "./PaycardsOverview.vue?vue&type=template&id=4d9c24e3&scoped=true"
import script from "./PaycardsOverview.vue?vue&type=script&lang=ts"
export * from "./PaycardsOverview.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/scoped/paycards-overview-style.css?vue&type=style&index=0&id=4d9c24e3&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d9c24e3",
  null
  
)

/* custom blocks */
import block0 from "@/assets/locales/i18n-paycards-overview.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2FPaycardsOverview.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports