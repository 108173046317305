
import Vue from "vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import {CredentialModel} from "@/models/CredentialModel";
import {AuthenticationRequests} from "@/scripts/ProfiControlRequests/AuthenticationRequests";
import {CredentialServiceError} from "@/Enums";
import LogoEdit from "@/components/LogoEdit.vue";
import {PersonalizedLogo} from "@/models/PersonalizedLogo";
import {IssuerCardsRequests} from "@/scripts/ProfiControlRequests/IssuerCardsRequests";

export default Vue.extend({
  components: {LogoEdit, BaseButton},
  props:{
    personalizedLogoModel: PersonalizedLogo
  },
  data: () => ({
    credentialModel: new CredentialModel(),
    showCurrentPassword: false,
    showNewPassword: false,
    showNewPasswordRepeat: false,
    startViewActive: true,
    accountViewActive: false,
    showError: false,
    showSuccess: false,
    format: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
    passwordRules: {
      MinLength: 9,
      MaxLength: 20,
      MinCountEnoughNumbers: 1,
      MinCountUpperChars: 1,
      MinCountLowerChars: 1,
      MinCountSpecialSigns: 1,
      Valid: false
    },
    maximumImageWidth: 1920,
    maximumImageHeight: 1280,
    dpi: 300,
  }),
  computed: {
    passwordValidation(): boolean {
      return !this.passwordRules.Valid;
    },
    rules() {
      return {
        required: (value: string) =>
            !!value ||
            this.$t('PasswordRules.HasPassword'),
        sameValue: (valueA: string, valueB: string) =>
            valueA === valueB ||
            this.$t('PasswordRules.PasswordConfirmationInvalid'),
        max: (maxLength: number, value: string) =>
            value == undefined ||
            value.length <= maxLength ||
            this.$t('PasswordRules.HasPasswordExceededMaxLength', {maxLength: maxLength}),
        min: (minLength: number, value: string) =>
            value == undefined ||
            value.length >= minLength ||
            this.$t('PasswordRules.IsPasswordLongEnough', {minLength: minLength}),
        enoughNumbers: (minNumberCount: number, value: string) =>
            value == undefined ||
            (value.match(/\d/g) || []).length >= minNumberCount ||
            this.$t('PasswordRules.HasEnoughNumbers', {minNumberCount: minNumberCount}),
        enoughUpperCases: (minUpperCases: number, value: string) =>
            value == undefined ||
            (value.match(/[A-Z]/g) || []).length >= minUpperCases ||
            this.$t('PasswordRules.HasEnoughUpperChars', {minUpperCases: minUpperCases}),
        enoughLowerCases: (minLowerCases: number, value: string) =>
            value == undefined ||
            (value.match(/[a-z]/g) || []).length >= minLowerCases ||
            this.$t('PasswordRules.HasEnoughLowerChars', {minLowerCases: minLowerCases}),
        enoughSpecialSigns: (minSpecialSigns: number, value: string) =>
            value == undefined ||
            (value.match(this.format) || []).length >= minSpecialSigns ||
            this.$t('PasswordRules.HasEnoughSpecialSigns', {minSpecialSigns: minSpecialSigns})
      }
    },
  },
  methods: {
    Back() {
      this.$emit('back');
    },
    SavePassword() {
      let url = this.$config.GatewayUrl + '/api/v1/issuer/password/change';
      let token = this.$cookies.get("token");
      AuthenticationRequests.ResetPassword(url, token, this.credentialModel);
    },
    ShowError() {
      this.showSuccess = false;
      this.showError = true
      setTimeout(() => {
        this.showError = false
      }, 5000)
    },
    UpdatePersonalizedLogo() {
      let url = `${this.$config.GatewayUrl}/api/v1/personalized/logo`;
      let token = this.$cookies.get('token');
      IssuerCardsRequests.UpdatePersonalizedLogo(url, token, this.personalizedLogoModel);
    },
    ShowSuccess() {
      this.showError = false;
      this.showSuccess = true
      setTimeout(() => {
        this.showSuccess = false
        this.Logout();
      }, 2000)
    },
    Logout() {
      this.$emit("logout");
    }
  },
  watch: {
    'credentialModel.CredentialError': function () {
      if (!this.credentialModel.IsReset) {
        this.credentialModel.CredentialError == CredentialServiceError.None
            ? this.ShowSuccess()
            : this.ShowError();
        this.credentialModel.Reset();
      }
    },
  }
});
