import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{attrs:{"app":""}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Logo","contain":"","src":require("@/assets/images/logo-appbar.png"),"transition":"scale-transition","width":"40"}}),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('Title')))])],1),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.ChangeLanguage('de')}}},[_vm._v("DE")]),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.ChangeLanguage('en')}}},[_vm._v("EN")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.ChangeDarkMode()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.$vuetify.theme.dark ? "mdi-water" : "mdi-water")+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("DarkMode")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }