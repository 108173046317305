import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.backgroundColor,"dark":""}},[_c(VCardTitle,{staticClass:"headline",style:(_vm.textColor)},[_vm._v(_vm._s(_vm.Headline))])],1),_c('div',{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll.self",value:(_vm.OnScroll),expression:"OnScroll",modifiers:{"self":true}}],staticClass:"error-section pa-5"},_vm._l((_vm.getErrorMessages),function(error){return _c('div',[_c(VAlert,{attrs:{"border":"right","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" "+_vm._s(error)+" ")])],1)}),0),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"buttonText":_vm.ButtonConfirm,"isDisabled":false},on:{"click":_vm.Confirm}}),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }