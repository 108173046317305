import { render, staticRenderFns } from "./AppBar.vue?vue&type=template&id=00e6b8f7"
import script from "./AppBar.vue?vue&type=script&lang=js"
export * from "./AppBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/assets/locales/i18n-appbar.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2FAppBar.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports