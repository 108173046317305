import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=e8c52bca&scoped=true"
import script from "./Login.vue?vue&type=script&lang=ts"
export * from "./Login.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/scoped/login-style.css?vue&type=style&index=0&id=e8c52bca&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8c52bca",
  null
  
)

/* custom blocks */
import block0 from "@/assets/locales/i18n-login.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2FLogin.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/assets/locales/i18n-passwordRuleTexts.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Ftses-smartregion%2Fstadtkarte%2Fuis%2Fprofishop-issuer-ui%2Fsrc%2Fcomponents%2FLogin.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports