import {ReportDownloadModel} from "@/models/ReportDownloadModel";
import {ReportDownload} from "@/scripts/Common/ReportDownload";
import {CrudManager} from "@/scripts/ProfiControlRequests/CrudManager";

const downloadReport = (url: string, token: string, fileModel: ReportDownloadModel, language?: string) => {
    CrudManager.Get(url, token, language)
        .then((response) => {
            if (!!response.data) {
                fileModel.Data = response.data
                ReportDownload.Create(fileModel);
            }
        })
        .catch(_ => {
        });
}

export {
    downloadReport
}
