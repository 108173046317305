export class FileDownload {
    
    public static Create(content:any, filename:string) {
        let fileURL = window.URL.createObjectURL(new Blob([content]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
    }

}