import {CrudManager} from "@/scripts/ProfiControlRequests/CrudManager";
import {WebchargeSettingDto} from "@/models/WebchargeSettingDto";
import {WebchargeSetting} from "@/models/WebchargeSetting";

export class SettingRequester {

    public static getWebchargeSettings(url: string, token: string, config: WebchargeSetting, lang: string): void {
        CrudManager.Get<WebchargeSettingDto>(url, token, lang)
            .then(response => {
                config.setValues(response.data);
            }).catch(_ => {
            config.hasConfigurationError = true;
        });
    }
}