import {WebchargeSettingDto} from "@/models/WebchargeSettingDto";
import {Constants} from "@/scripts/Common/Constants";
import {HelperFunctions} from "@/scripts/Common/HelperFunctions";

export class WebchargeSetting {
    public webchargeConfigurationModel:WebchargeSettingDto = new WebchargeSettingDto();

    public hasSpecificChargingDays:boolean = false;
    public chargingDays:Array<string> | undefined = undefined;
    public hasConfigurationError:boolean = false;
    public configurationErrors:object = {};

    constructor(webchargeConfigurationModel?:WebchargeSettingDto) {
        if(!!webchargeConfigurationModel)
            this.setValues(webchargeConfigurationModel);
    }

    public setValues(webchargeConfigurationModel:WebchargeSettingDto){
        this.webchargeConfigurationModel = webchargeConfigurationModel;
        this.hasSpecificChargingDays = webchargeConfigurationModel.hasSpecificChargingDays;
        this.chargingDays = webchargeConfigurationModel.chargingDays;
    }

    public getEnabledDates(val: string) {
        //parseInt um die führenden Nullen der Elemente 1-9 zu entfernen
        let splitDate = val.split('-');
        let day = parseInt(splitDate[2], 10);
        let month = parseInt(splitDate[1], 10);
        let year = parseInt(splitDate[0], 10);
        
        if (!this.hasSpecificChargingDays) {
            return day;
        }

        let isDayActivatedForWebcharges = this.chargingDays?.includes(day.toString()) ||
            (this.chargingDays?.includes(Constants.ULTIMO) &&
                HelperFunctions.getLastDayOfMonth(year, month) === day);

        if (isDayActivatedForWebcharges)
            return day;
    }
}