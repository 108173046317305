
export class HeaderBuilder {

    public static Build(token: string, language?: string ,contentType?: string) {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept-Language': !!language ? language : 'de',
                'Content-Type': !!contentType ? contentType : 'application/json'
            },
        };
        return config;
    }

}