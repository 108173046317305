import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.backgroundColor,"dark":""}},[_c(VCardTitle,{staticClass:"dialog-headline",style:(_vm.textColor)},[_vm._v(_vm._s(_vm.heading))])],1),_c(VCardText,{staticClass:"content"},[_c(VForm,{ref:"dialogForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,[_c(VCol,{attrs:{"xs":"12","sm":"6","md":"6","lg":"6"}},[_c(VTextField,{attrs:{"type":"date","label":_vm.$t('StartDate'),"rules":[_vm.rules.required]},on:{"change":_vm.validateDialogForm},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c(VCol,{attrs:{"xs":"12","sm":"6","md":"6","lg":"6"}},[_c(VTextField,{attrs:{"type":"date","label":_vm.$t('EndDate'),"rules":[_vm.rules.required, _vm.rules.contractEndAfterStart(_vm.endDate)]},on:{"change":_vm.validateDialogForm},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"cssClass":"setting-buttons-with-mb","buttonText":_vm.$t('Dialog.Ok'),"backgroundColor":_vm.backgroundColor,"textColor":_vm.textColor,"isDisabled":!_vm.isValid},on:{"click":_vm.confirm}}),_c('BaseButton',{attrs:{"cssClass":"setting-buttons-with-mb","buttonText":_vm.$t('Dialog.Cancel'),"backgroundColor":_vm.backgroundColor,"textColor":_vm.textColor},on:{"click":_vm.cancel}}),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }