import {CrudManager} from "@/scripts/ProfiControlRequests/CrudManager";
import {CredentialModel} from "@/models/CredentialModel";
import {CredentialServiceError} from "@/Enums";

export class AuthenticationRequests {
    
    public static CheckCardNumber(url:string, credentialModel:CredentialModel) : void 
    {
        CrudManager.Get<boolean>(url)
            .then(response => { credentialModel.CardExists = response.data; })
            .catch(_ => { credentialModel.CredentialError = CredentialServiceError.Unknown });
    }
    
    public static CheckPasswordState(url:string, credentialModel:CredentialModel) : void
    {
        CrudManager.Get<boolean>(url)
            .then(response => { credentialModel.PasswordIsSet = response.data; })
            .catch(_ => { credentialModel.CredentialError = CredentialServiceError.Unknown });
    }
    
    public static Login(url:string, credentialModel:CredentialModel) : void
    {
        let data = {CardNumber: credentialModel.CardNumber, Password: credentialModel.CurrentPassword};
        CrudManager.Post<string>(url, data)
            .then(response => {
                credentialModel.Token = response.data
            })
            .catch(_ => { credentialModel.CredentialError = CredentialServiceError.Unknown });
    }
    
    public static Register(url:string, credentialModel:CredentialModel) : void
    {
        let data = {CardNumber: credentialModel.CardNumber, Password: credentialModel.NewPassword};
        CrudManager.Post<string>(url, data)
            .then(response => {
                credentialModel.Token = response.data
            })
            .catch(_ => { credentialModel.CredentialError = CredentialServiceError.Unknown });
    }
    
    public static ResetPassword(url:string, token:string, credentialModel:CredentialModel) : void
    {
        let data = {CurrentPassword: credentialModel.CurrentPassword, NewPassword: credentialModel.NewPassword};
        CrudManager.Post(url, data, token)
            .then(_ => { credentialModel.CredentialError= CredentialServiceError.None; })
            .catch(_ => { 
                credentialModel.CredentialError = CredentialServiceError.WrongCurrentPassword; 
            });
    }
    
}