
import Vue from "vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import {IssuerCardImportDto} from "@/models/IssuerCardImportDto";
import {FileDownload} from "@/scripts/Common/FileDownload";

export default Vue.extend({
  components: {BaseButton},
  props: {
    model:IssuerCardImportDto,
    textColor: String,
    backgroundColor: String,
  },
  data: function () {
    return {
      isActive: false,
    };
  },
  methods: {
    Show: function(){
      this.isActive = true;
    },
    Hide: function (){
      this.isActive = false;
    },
    Confirm: function (){
      this.$emit("Confirmed");
      this.Hide();
    },
    DownloadCsv: function (){
      if(!!this.model.failedImports && this.model.failedImports > 0){
        FileDownload.Create(this.model.incorrectLinesAsCsv, this.$t("CsvFileName").toString());
      }
    }
  },
});
