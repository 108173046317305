
import Vue from "vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default Vue.extend({
  components: {BaseButton},
  props: {
    Headline: String,
    Errors: {
      type: [String, Array],
      required: true
    },
    ButtonConfirm: String,
    textColor: String,
    backgroundColor: String,
  },
  computed: {
    getErrorMessages(): Array<string>{
      if(this.Errors.length === 0) {
        (this.Errors as Array<string>).push(this.$t('DefaultError').toString());
      }
      return this.Errors as Array<string>;
    }
  },
  data: function () {
    return {
      isActive: false,
    };
  },
  methods: {
    OnScroll() {
    },
    Show: function () {
      this.isActive = true;
    },
    Hide: function () {
      this.isActive = false;
    },
    Confirm: function () {
      this.Hide();
    },
  },
});
