import moment from "moment";
import {ReportDownloadModel} from "@/models/ReportDownloadModel";

export class ReportDownload{

    public static Create(model:ReportDownloadModel) : void
    {
        let fileURL = window.URL.createObjectURL(new Blob([model.Data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        let fileName = this.BuildFileName(model);
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
    }

    private static BuildFileName(model:ReportDownloadModel) : string
    {
        return !!model.EndDate
            ? model.Filename+"_"+ model.StartDate +"_"+ model.EndDate +".csv"
            : model.Filename+"_"+ model.StartDate +".csv";
    }
    
}