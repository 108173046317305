import {CrudManager} from "@/scripts/ProfiControlRequests/CrudManager";
import {IssuerCard} from "@/models/IssuerCard";
import {PaycardStateDto} from "@/models/PaycardStateDto";
import {WebChargeStatus} from "@/Enums";
import moment from "moment";
import {DateFormats} from "@/scripts/Common/DateFormats";
import {CardLimite} from "@/models/CardLimite";
import {PersonalizedLogo} from "@/models/PersonalizedLogo";
import {PersonalizedLogoDto} from "@/models/PersonalizedLogoDto";
import {IssuerCardsDto} from "@/models/IssuerCardsDto";
import {IssuerCards} from "@/models/IssuerCards";

export class IssuerCardsRequests {
    
    public static Get(url:string, token:string, cards:IssuerCards) : void
    {
        CrudManager.Get<IssuerCardsDto>(url, token)
            .then((response) => {
                cards.SetCards(response.data.cards);
            })
            .catch((_) => {});
    }
    
    public static RefreshCardState(url:string, token:string, issuer:IssuerCards, cardNumbers:Array<number>) : void
    {
        CrudManager.Post<Array<PaycardStateDto>>(url, cardNumbers, token)
            .then((response) => {
                for (let card of issuer.GetCards()) {
                    let status = response.data.find(x => x.number === card.CardNumber);
                    if(status != undefined){
                        card.IsActive = status.isActive;
                        card.ChargeDate = moment(status.lastChargeDate, DateFormats.ISO8601_LONG);
                        card.ChargeStatus = status.lastChargeStatus;
                        card.LastChargeProtocolId = status.lastChargeProtocolId;
                    }
                }
            })
            .catch((_) => {});
    }

    public static RepeateWebCharge(url:string, token:string, card:IssuerCard) : void
    {
        CrudManager.Put<WebChargeStatus>(url, undefined, token)
            .then((response) => {
                card.ChargeStatus = response.data;
            })
            .catch((_) => {});
    }

    public static GetCardLimite(url:string, token:string, limite:CardLimite) : void
    {
        CrudManager.Get<CardLimite>(url, token)
            .then((response) => {
                limite.SetValues(response.data);
            })
            .catch((_) => {});
    }
    
    public static GetPersonalizedLogo(url:string, token:string, model:PersonalizedLogo) : void
    {
        CrudManager.Get<PersonalizedLogoDto>(url, token)
            .then((response) => {
                model.setValues(response.data);
            })
            .catch((_) => {});
    }

    public static UpdatePersonalizedLogo(url:string, token:string, model:PersonalizedLogo) : void
    {
        CrudManager.Put<PersonalizedLogoDto>(url, model, token)
            .then((response) => {
                model.setValues(response.data);
            })
            .catch((_) => {});
    }
}

const CatchCardError = (error:any, cardsModel:IssuerCards) => {
    cardsModel.HasRequestErrors = true;
    if(!!error.response?.data?.errors){
        cardsModel.RequestErrors = error.response.data.errors;
    }
    if(!!error.response?.data?.error){
        cardsModel.RequestError = error.response.data.error.value;
    }
}

const UpdateCards = async (url:string, token:string, cards:IssuerCards, language:string)  => {
    let responseData;
    let errorResponse;
    
    try {
        responseData = await CrudManager.Put<IssuerCardsDto>(url, cards.GetModel(), token, language)
    }
    catch (error){
        errorResponse = error
    }
    return {
        responseData,
        errorResponse
    }
}

export {
    UpdateCards,
    CatchCardError
}
