import {CredentialServiceError} from "@/Enums";

export class CredentialModel {
    private _cardNumber:string = '';
    private _currentPassword:string = ''
    private _newPassword:string = ''
    private _newPasswordConfirm:string = '';
    private _credentialError:CredentialServiceError = CredentialServiceError.Stateless;
    private _passwordIsSet: boolean = true;
    private _cardExists: boolean = false;
    private _token:string = '';
    private _isReset: boolean = false;
    
    public set CardNumber(value:string){
       this._isReset = false;
       this._cardNumber = value;
    }
    
    public get CardNumber(){
        return this._cardNumber;
    }

    public set CurrentPassword(value:string){
        this._isReset = false;
        this._currentPassword = value;
    }

    public get CurrentPassword(){
        return this._currentPassword;
    }

    public set NewPassword(value:string){
        this._isReset = false;
        this._newPassword = value;
    }

    public get NewPassword(){
        return this._newPassword;
    }

    public set NewPasswordConfirm(value:string){
        this._isReset = false;
        this._newPasswordConfirm = value;
    }

    public get NewPasswordConfirm(){
        return this._newPasswordConfirm;
    }

    public set CredentialError(value:CredentialServiceError){
        this._isReset = false;
        this._credentialError = value;
    }

    public get CredentialError(){
        return this._credentialError;
    }

    public set PasswordIsSet(value:boolean){
        this._isReset = false;
        this._passwordIsSet = value;
    }

    public get PasswordIsSet(){
        return this._passwordIsSet;
    }

    public set CardExists(value:boolean){
        this._isReset = false;
        this._cardExists = value;
    }

    public get CardExists(){
        return this._cardExists;
    }

    public set Token(value:string){
        this._isReset = false;
        this._token = value;
    }

    public get Token(){
        return this._token;
    }

    public get IsReset(){
        return this._isReset;
    }
    
    public Reset(){
        this._cardNumber = '';
        this._currentPassword = '';
        this._newPassword = '';
        this.NewPasswordConfirm = '';
        this.CredentialError = CredentialServiceError.Stateless;
        this.PasswordIsSet = true;
        this.CardExists = false;
        this.Token = '';
        this._isReset = true;
    }
}