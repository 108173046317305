import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.backgroundColor,"dark":""}},[_c(VCardTitle,{staticClass:"headline",style:(_vm.textColor)},[_vm._v(_vm._s(_vm.Headline))])],1),_c(VCardText,{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.Content)+" ")]),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"buttonText":_vm.ButtonConfirm,"isDisabled":false,"backgroundColor":_vm.$config.Design.AccentColor1,"textColor":'color:' + _vm.$config.Design.TextColor1},on:{"click":_vm.Confirm}}),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }